import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  PageBody,
  TextTitle,
  Spacer,
  PageSection,
  DataTable,
  DataTableWC,
  logos,
  Stack,
  ButtonGroup,
  ModalProps,
  Text,
} from '@pypestream/design-system';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  useTranslation,
} from '@pypestream/translations';
import { formatDistance, parseISO } from 'date-fns';
import { html } from 'lit/static-html.js';

import {
  CreateProjectButton,
  DEFAULT_SQUARE_ICON_BASE64,
  Loader,
} from '../../../components';
import {
  sendManagerEvent,
  useManagerStateMatches,
  useManagerCtxSelector,
} from '../../../xstate/app.xstate';
import { initialTools } from '../../../utils';
import { CreateProjectFlow } from '../../../components/create-project/create-project-flow';
import { OrganizationRoleNames, useAuthRole } from '../../../hooks';

export const Projects: React.FC = () => {
  const loaded = useManagerStateMatches('orgRelated.ready.projects.loaded');
  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);
  const modalRef = useRef<ModalProps>(null);

  const { projects, products, orgId, defaultLanguage } = useManagerCtxSelector(
    (ctx) => ({
      orgId: ctx.selectedOrgId,
      projects: ctx.projects,
      products: ctx.products,
      defaultLanguage: ctx.userInfo?.defaultLanguage,
    })
  );

  const navigate = useNavigate();

  const tableRef = useRef<DataTableWC>(null);
  const [t] = useTranslation();

  return (
    <>
      <PageBody background="none">
        <>
          <Stack alignItems="center" justifyContent="space-between">
            <TextTitle>
              <TranslationComponent i18nKey="manager/projects:projectsList.projects">
                Projects
              </TranslationComponent>
            </TextTitle>
            {isAdminRole && (
              <ButtonGroup>
                <CreateProjectButton
                  onClick={() => {
                    if (modalRef.current) {
                      modalRef.current.open = true;
                    }
                  }}
                />
              </ButtonGroup>
            )}
          </Stack>
          <Spacer size="2xlarge" />
          <PageSection>
            {!loaded ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loader relative />
              </div>
            ) : (
              <>
                <DataTable
                  test-id="projects-table"
                  ref={tableRef}
                  columns={[
                    {
                      accessorKey: 'name',
                      header: () =>
                        t('manager/projects:projectsList.table.name', {
                          defaultValue: 'Name',
                        }) || '',
                      cell: ({ row, renderValue }) => html`
                        <ps-stack
                          gutter="small"
                          justifyContent="start"
                          alignItems="center"
                          direction="row"
                          nowrap
                        >
                          <ps-image
                            width="48px"
                            height="48px"
                            src=${row.original?.pictureFile?.url || ''}
                            fallback=${DEFAULT_SQUARE_ICON_BASE64}
                          >
                          </ps-image>

                          <ps-stack
                            gutter="none"
                            display="inline-flex"
                            direction="column"
                          >
                            <ps-text size="2xsmall" font-weight="medium"
                              >${row.original?.name}</ps-text
                            >
                            ${row.original?.description
                              ? html`<ps-text size="2xsmall" variant="secondary"
                                  >${row.original?.description}</ps-text
                                >`
                              : ''}
                          </ps-stack>
                        </ps-stack>
                      `,
                    },
                    {
                      accessorKey: 'users',
                      header: () =>
                        t('manager/projects:projectsList.table.users', {
                          defaultValue: 'Users',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-avatar-group max="2">
                          ${row.original?.users?.rows?.map(
                            (user) =>
                              html`<ps-avatar
                                label="${user.firstName}"
                                src="${user.picture || ''}"
                              ></ps-avatar>`
                          )}
                        </ps-avatar-group>`,
                      // html`${row.original.project?.users
                      //   ?.count}${row.original.project?.users?.rows?.map((user) => {
                      //   console.log('user.email', user.email);
                      //   return html`${user.email}`;
                      // })}`,

                      //
                    },
                    {
                      accessorKey: 'tools',
                      header: () =>
                        t('manager/projects:projectsList.table.tools', {
                          defaultValue: 'Tools',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`${row.original?.projectProductSettings?.map(
                          (product) => {
                            const productFound = products?.filter(
                              (item) => item.id === product.productId
                            );

                            if (!productFound) return html``;

                            const productLogo = initialTools.find(
                              (tool) =>
                                tool.label === productFound[0].displayName
                            )?.logo;

                            if (productLogo) {
                              return html`<ps-inline-list
                                ><img
                                  src=${logos[productLogo]}
                                  width="24px"
                                  alt=${productFound[0].displayName?.toLowerCase() ||
                                  ''}
                              /></ps-inline-list>`;
                            }

                            if (productFound) {
                              return html`<ps-inline-list
                                >${productFound[0].displayName}</ps-inline-list
                              >`;
                            }

                            return html``;
                          }
                        )}`,
                    },
                    {
                      accessorKey: 'updated',
                      header: () =>
                        t('manager/projects:projectsList.table.updated', {
                          defaultValue: 'Last updated',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${formatDistance(
                            parseISO(row.original?.updatedAt),
                            new Date(),
                            {
                              addSuffix: true,
                              locale:
                                dateFnsLocales[
                                  defaultLanguage || fallbackLanguage
                                ],
                            }
                          )}</ps-text-body
                        >`,
                    },
                  ]}
                  data={projects?.map((project) => ({
                    ...project,
                    id: project.projectId,
                  }))}
                  onRowSelectionChange={(event) => {
                    if (event.detail.selected && event.detail.selected.length) {
                      event.detail.selected.map((item) => {
                        const dataTable = event.currentTarget as DataTableWC;

                        dataTable['table'].resetRowSelection();

                        sendManagerEvent({
                          type: 'manager.selectProject',
                          id: item.original?.projectId,
                        });

                        navigate(
                          `/organization/${orgId}/projects/${item.original?.projectId}`
                        );

                        return item;
                      });
                    }
                  }}
                  enableRowSelection
                  // enableRowSelectionUi={false}
                ></DataTable>

                {!projects ||
                  (!projects.length && (
                    <Text
                      size="xsmall"
                      textAlign="center"
                      className="u-margin-top-xlarge"
                    >
                      <TranslationComponent
                        i18nKey={`manager/common:homepage.projects.${isAdminRole ? 'toGetStartedCreateProject' : 'nothingToShowHere'}`}
                      >
                        {isAdminRole
                          ? 'To get started, create your first project.'
                          : 'Nothing to show here...'}
                      </TranslationComponent>
                    </Text>
                  ))}
              </>
            )}
          </PageSection>
        </>
      </PageBody>
      <CreateProjectFlow modalRef={modalRef} />
    </>
  );
};
