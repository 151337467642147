import { SmartContext } from '../xstate/smart.xstate-utils';
import { generateBaseURLsToTools } from './generate-base-urls/generate-base-urls';

export const handleGoHome = (accountId: SmartContext['accountId']) => {
  const url = `${generateBaseURLsToTools(window.location).Manager}${accountId ? `/organization/${accountId}` : ''}`;

  console.log(
    '%curl------------------->',
    'color: orange; font-size: larger; font-weight: bold',
    url,
    window.location
  );

  window.location.href = url;
};
