/* eslint-disable react/prop-types */
import {
  Input,
  InputProps,
  Select,
  SelectOption,
  SelectProps,
  Filepond,
  EntityTypes,
} from '@pypestream/design-system';
import { useTranslation } from '@pypestream/translations';
import { FC, ForwardedRef, forwardRef } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import { FormOrgInfoType } from './create-organization';
import { GetUsersQuery } from '@pypestream/api-services/urql';
import { useManagerCtxSelector } from '../../../xstate/app.xstate';

export type ReturnFormType = UseFormReturn<FormOrgInfoType, unknown, undefined>;

export type Users = NonNullable<
  NonNullable<GetUsersQuery['admin_']>['users']
>['rows'];

export class CreateOrganizationFields {
  public OrganizationNameField = forwardRef(
    (
      props: InputProps & {
        _form: ReturnFormType;
        autosave?: boolean;
      },
      ref: ForwardedRef<InputProps>
    ) => {
      const { t } = useTranslation();

      const { field: nameField, fieldState: nameFiledState } = useController({
        name: 'name',
        control: props._form.control || undefined,
        rules: { required: 'This is a required field.' },
      });

      const field = (
        <Input
          test-id="org-name-input"
          placeholder={`${t('manager/organizations:createOrg.placeholders.name')}`}
          variant="outlined"
          type="text"
          autocomplete="off"
          value={nameField.value}
          helpText={nameFiledState.error?.message}
          hasError={nameFiledState.invalid}
          name={nameField.name}
          readonly={props.readonly}
          onInput={nameField.onChange}
        />
      );

      return field;
    }
  );

  public OrganizationEmailDomainField = forwardRef(
    (
      props: InputProps & {
        _form: ReturnFormType;
        autosave?: boolean;
      },
      ref: ForwardedRef<InputProps>
    ) => {
      const { t } = useTranslation();

      const { control } = props._form;

      const { field: emailDomainField, fieldState: emailDomainFiledState } =
        useController({
          name: 'emailDomain',
          control: control || undefined,
          rules: { required: 'This is a required field.' },
        });

      const field = (
        <Input
          test-id="org-domain-input"
          placeholder={`${t('manager/organizations:createOrg.placeholders.emailDomain')}`}
          variant="outlined"
          type="text"
          autocomplete="off"
          value={emailDomainField.value}
          helpText={emailDomainFiledState.error?.message}
          hasError={emailDomainFiledState.invalid}
          name={emailDomainField.name}
          onInput={emailDomainField.onChange}
          disabled={props.disabled}
        />
      );

      return field;
    }
  );

  public OrganizationAuthProviderField = forwardRef(
    (
      props: SelectProps & {
        _form: ReturnFormType;
        autosave?: boolean;
        parentId: string;
      },
      ref: ForwardedRef<SelectProps>
    ) => {
      const { t } = useTranslation();

      const { field: authProviderField, fieldState: authProviderFiledState } =
        useController({
          name: 'authProvider',
          control: props._form.control || undefined,
          rules: { required: 'This is a required field.' },
        });

      const field = (
        <Select
          test-id="org-auth-provider-input"
          variant="outlined"
          placeholder={`${t('manager/organizations:createOrg.placeholders.authProvider')}`}
          ref={ref}
          value={authProviderField.value}
          helpText={authProviderFiledState.error?.message}
          hasError={authProviderFiledState.invalid}
          clearable
          disabled={props.disabled}
          onChange={(e) => authProviderField.onChange(e.target.value)}
        >
          {[
            // ...(props.parentId
            //   ? [
            //       {
            //         id: 'same-as-parent',
            //         name: 'Same auth as parent organization',
            //       },
            //     ]
            //   : []),
            {
              id: 'password',
              name: 'Email & password',
            },
            {
              id: 'google-sso',
              name: 'Google SSO',
            },
          ].map((authProvider) => (
            <SelectOption
              test-id={`${authProvider.id}-option`}
              key={authProvider.id}
              value={authProvider.id}
            >
              {authProvider.name}
            </SelectOption>
          ))}
        </Select>
      );

      return field;
    }
  );

  public OrganizationIconField: FC<{
    form: ReturnFormType;
    organizationId: string;
    readOnly: boolean;
  }> = ({ form, organizationId, readOnly }) => {
    const { t } = useTranslation();

    const { field: orgIconField } = useController({
      name: 'picture',
      control: form.control || undefined,
    });

    const { field: orgIconIdField } = useController({
      name: 'accountIconId',
      control: form.control || undefined,
    });

    const accountId = useManagerCtxSelector((ctx) => ctx.selectedOrgId);

    return (
      <Filepond
        readonly={readOnly}
        entityType={EntityTypes.ACCOUNT}
        entityId={organizationId}
        accountId={accountId}
        previewLabel={
          t('manager/organizations:createOrg.placeholders.dragPicture', {
            defaultValue: 'Drag an photo here',
          }) || ''
        }
        previewCta={
          t('manager/organizations:createOrg.placeholders.selectFile', {
            defaultValue: 'Select a file',
          }) || ''
        }
        pictureSrc={orgIconField.value}
        name={orgIconField.name}
        onChange={({ url, pictureId }) => {
          orgIconField.onChange(url);
          orgIconIdField.onChange(pictureId);
        }}
      ></Filepond>
    );
  };

  public OrganizationAccountManagerField: FC<{
    users: Users;
  }> = ({ users, ...props }) => {
    const { t } = useTranslation();

    return (
      <Select
        variant="outlined"
        placeholder={`${t('manager/organizations:createOrg.placeholders.accountManager')}`}
        clearable
        {...props}
      >
        {(users || []).map((accountManager) => (
          <SelectOption key={accountManager.id} value={accountManager.id}>
            {`${accountManager.firstName} ${accountManager.lastName} (${accountManager.userName})`}
          </SelectOption>
        ))}
      </Select>
    );
  };
}
